import React from "react";
import {INavLink} from "@jorsek/content-portal-client/types"
import {WithRouterProps} from "next/dist/client/with-router";
import {withRouter} from "next/router";
import {Breadcrumbs, withStyles, createStyles, withTheme} from "@material-ui/core";
import PortalLink from "./PortalLink";
import I18nMessage from "./i18n/I18nMessage";
import PortalFontIcon from './PortalFontIcon';

const portalBreadcrumbStyles = (theme: any) => createStyles({
    portalBreadcrumbHref: theme.portalBreadcrumbHref,
    portalBreadcrumbText: theme.portalBreadcrumbText,
    portalBreadcrumb: theme.portalBreadcrumb,
    portalBreadcrumbIcon: theme.portalBreadcrumbIcon,
});


interface IPortalBreadcrumbsProps extends WithRouterProps {
    classes?: any;
    basePath?: string;
    breadcrumbs: INavLink[];
    shallow?: boolean;
    theme?: any;
    skipFormatting?: boolean
    section?: any
}

const homeCrumb = {
    title: "label.home",
    href: "/"
};

const PortalBreadcrumb = (props: IPortalBreadcrumbsProps) => {
    const {classes, basePath} = props;
    // fix breadcrumbs sent by the API:
    // 1. omit first breadcrumb
    // 2. omit last breadcrumb corresponding to current page, don't need to show this in the breadcrumbs
    // 3. combine section and version breadcrumbs
    let siteSection = null as any, version = null as any, rendered_crumbs = [];
    // if(!props.skipFormatting){
    props.breadcrumbs.forEach((crumb: any, i: number) => {
        if (!crumb.title) {
            return;
        }
        switch (crumb.type) {
            case "version":
                version = crumb;
                break;
            case "sitesection":
                if (!siteSection) {
                    siteSection = crumb;
                } else {
                    rendered_crumbs.push(crumb);
                }
                break;
            case "topichead":
                rendered_crumbs.push(crumb);
                break;
            case "topicref":
                if (i < props.breadcrumbs.length - 1) {
                    rendered_crumbs.push(crumb);
                }
                break;
        }
    })
    let title = '';
    let href = siteSection?.href;
    if (siteSection && siteSection.title) {
        title += `${siteSection.title}`
    }
    if (version && version.title) {
        title += ` (${version.title})`;
        href = version.href || href;
    }

    props.skipFormatting ? rendered_crumbs.unshift(homeCrumb) : (title && rendered_crumbs.unshift({...version, title, href}));

    return (
        <Breadcrumbs
            classes={{separator: classes.portalBreadcrumb}}
            separator={props.theme?.portalBreadcrumb?.useStringSeparator ?
                            <span className={classes.portalBreadcrumbIcon}>{props.theme.portalBreadcrumb?.separator}</span> 
                            : <PortalFontIcon name={props.theme.portalBreadcrumb?.separator} 
                                        className={classes.portalBreadcrumbIcon}
                                        color="primary"
                                        fontSize="small"/>}>
            {rendered_crumbs.map((crumb, i) => (
                        <I18nMessage id={crumb.title}
                                     Component={PortalLink}
                                     className={classes.portalBreadcrumbHref}
                                     key={crumb.href + i}
                                     href={basePath + (crumb.contentHref || crumb.href)}
                                     shallow={props.shallow}/>)
            )}
        </Breadcrumbs>
    );
}
PortalBreadcrumb.defaultProps = {
    breadcrumbs: [],
    basePath: ""
}
export default withRouter(withTheme(withStyles(portalBreadcrumbStyles, {name: "PortalBreadcrumb"})(PortalBreadcrumb)));
