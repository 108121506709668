import React from "react";
import {withStyles, createStyles} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import {WithRouterProps} from "next/dist/client/with-router";
import {withRouter} from "next/router";
import PortalPopover from "./PortalPopover";
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";
import PortalFontIcon from "./PortalFontIcon";

const versionSelectorStyles = createStyles((theme: any) => ({
    versionSelector: theme.versionSelector,
    versionSelectorItem: theme.versionSelectorItem,
    versionSelectorItemPrefix: theme.versionSelectorItemPrefix,
    versionSelectorItemLabel: theme.versionSelectorItemLabel,
    versionSelectorButton: theme.versionSelectorButton,
    versionSelectorCurrent: theme.versionSelectorCurrent,
    versionSelectorCurrentPrefix: theme.versionSelectorCurrentPrefix,
    versionSelectorCurrentLabel: theme.versionSelectorCurrentLabel,
}));

interface IVersionSelectorProps extends WithRouterProps {
    classes?: any;
    versions: any;
    currentVersion: string,
}

const VersionSelector = (props: IVersionSelectorProps) => {
    const {classes} = props;
    if (props.versions.length < 2) {
        return null;
    }
    return (
        <PortalPopover
            className={classes.versionSelector}
            triggerProps={{
                className: classes.versionSelectorButton,
                variant: "outlined",
            }}
            popoverProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }}
            labelProps={{className: classes.versionSelectorCurrent}}
            label={(
                <>
                    <I18nMessage className={classes.versionSelectorCurrentPrefix}
                                 id={"label.version.prefix"}
                    />
                    <I18nMessage className={classes.versionSelectorCurrentLabel}
                                 id={"label.version.format"}
                                 values={{title: props.currentVersion}}/>
                    <PortalFontIcon name="arrow_drop_down"/>
                </>
            )}>
            {props.versions.map((link, index) =>
                <MenuItem key={link + index}
                          className={classes.versionSelectorItem}>
                    <PortalLink shallow={false} href={`${link.containedTopicHref || link.href}`}>
                        <I18nMessage className={classes.versionSelectorItemPrefix}
                                     id={"label.version.prefix"}
                        />
                        <I18nMessage className={classes.versionSelectorItemLabel}
                                     id={"label.version.format"}
                                     values={link}/>
                    </PortalLink>
                </MenuItem>
            )}
        </PortalPopover>
    );
};

VersionSelector.defaultProps = {
    versions: [],
    contentSubPath: ''
}
export default withRouter(withStyles(versionSelectorStyles, {name: "VersionSelector"})(VersionSelector))