import React from "react";
import {withStyles, createStyles} from "@material-ui/core";
import {withRouter} from "next/router";
import Layout from "../../components/Layout"
import SiteGroups from "../../components/SiteGroups";
import GenericGroups from "../../components/GenericGroups";
import {WithRouterProps} from "next/dist/client/with-router";
import SiteSectionTiles from "../../components/SiteSectionTiles";
import RenderHtml from "../../components/RenderHtml";
import getContentProps from "../../utils/getContentProps";
import {checkAuth} from "../../utils/auth";
import {removeAtDepth, findFirstTopicRef, findStart} from '../../utils/tree';
import ContentMeta from "../../components/ContentMeta";
import {assignSsoInfoProp} from "../../utils/PropUtil";
import {getClientSideConfig, handleForceStatusCode, handlePageError} from "../../utils/PageUtil";
import ErrorPage from "../../pages/error";
import HerettoDeployWindowObj, {ClientEvent} from "../../components/HerettoDeployWindowObj";

const siteSectionIndexStyles = (theme: any) => createStyles({
    siteSectionIndex: theme.siteSectionIndex,
    siteSectionIndexContent: theme.siteSectionIndexContent
});


class SiteSectionIndex extends React.Component<ISiteSectionIndexProps & WithRouterProps, any> {
    constructor(props) {
        super(props)
    }

    state = {
        section: null,
        structure: null,
        content: null,
        version: null
    }


    componentDidMount() {
        setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate))
    }

    componentDidUpdate() {
        setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate))
    }

    render() {
        if (this.props.showErrorPage) {
            return <ErrorPage {...this.props}/>
        }
        const {structure, content, section, runtimeConfig} = this.props;
        let contentThumbnail = content?.thumbnail || structure?.thumbnail || runtimeConfig?.defaultSectionIcon;
        const { videos, featured, whatsNew, relatedResources, externalSites = [], banners, footer, header, sidebar, ...other} = this.props.groups as any;
        return (
            <Layout {...this.props.layoutProps}
                    className={`section-index`}
            >
                <ContentMeta content={content}
                             meta={this.props.runtimeConfig?.meta}
                             seo={this.props.runtimeConfig?.seo || {}}
                             keywords={this.props.runtimeConfig?.keywords}
                             title={this.props.runtimeConfig?.title}
                />
                <SiteGroups
                    videos={videos}
                    featured={featured}
                    whatsNew={whatsNew}
                    relatedResources={relatedResources}
                    banners={banners}
                >

                    <SiteSectionTiles contentThumbnail={contentThumbnail}
                                      defaultIcon={this.props.runtimeConfig?.defaultSectionIcon}
                                      content={content}
                                      section={section}
                                      structure={structure}
                                      externalSites={this.props?.pageGroups?.externalSites || []}>
                        {(content && content.content) ? (
                            <div className={this.props.classes.siteSectionIndexContent}>
                                <RenderHtml>{content.content}</RenderHtml>
                            </div>
                        ) : null}
                    </SiteSectionTiles>
                </SiteGroups>
                <GenericGroups
                    className={this.props.classes.siteSectionIndex}
                    groups={other}
                >
                </GenericGroups>
            </Layout>
        );
    }
}

export async function getServerSideProps(args) {
    const {req, res, locale} = args;
    try {
        let {props} = await getContentProps(args, {
            ["Accept-Language"]: locale
        }) as any;
        const start = findStart(props.structure, props.content?.href)
        if (props.displayToc || !props.content || props.content.redirect || (!props.content.content && props.content.children.length)) {
            let firstTopic = (props.content && props.content.redirect) || findFirstTopicRef(start, true, props.displayToc)
            if (firstTopic && firstTopic.href !== props.content.href) {
                return {
                    redirect: {destination: `/${firstTopic.href}`, permanent: true}
                }
            }
        }

        if (req.runtimeConfig.prerenderToc) {
            props.structure = removeAtDepth(props.structure, 2)
        }
        
        props.sectionVersions = null;
        assignSsoInfoProp(props, req)
        handleForceStatusCode(res)
        props.runtimeConfig = getClientSideConfig(props.runtimeConfig)
        props.runtimeConfig.seo = req.runtimeConfig.seo
        return checkAuth(req) || JSON.parse(JSON.stringify({props}));
    } catch (ex) {
        return handlePageError(ex, locale, req, res)
    }
}

//@ts-ignore
export default withRouter(withStyles(siteSectionIndexStyles, {name: "SiteSectionIndex"})(SiteSectionIndex)) as any;