import React from "react";
import {withStyles, createStyles} from "@material-ui/core";
import VersionSelector from "./VersionSelector";
import I18nMessage from "./i18n/I18nMessage";
import PortalBreadcrumb from "./PortalBreadcrumb";
import PortalLink from "./PortalLink";

const siteSectionTileStyles = createStyles((theme: any) => ({
    siteSectionTiles: theme.siteSectionTiles,
    siteSectionTilesTitle: theme.siteSectionTilesTitle,
    siteSectionTilesIcon: theme.siteSectionTilesIcon,
    siteSectionTilesLinks: theme.siteSectionTilesLinks,
    siteSectionTilesLinksItem: theme.siteSectionTilesLinksItem,
    siteSectionTilesContainer: theme.siteSectionTilesContainer,
    siteSectionTilesWrapper: theme.siteSectionTilesWrapper,
    siteSectionHref: theme.siteSectionHref,
    siteSectionText: theme.siteSectionText,
    siteSectionTextWrapper: theme.siteSectionTextWrapper,
    siteSectionDescription: theme.siteSectionDescription,
    siteSectionIcon: theme.siteSectionIcon,
    siteSectionBreadcrumb: theme.siteSectionBreadcrumb
}));


const shortDescriptionFromMeta = (structureObj) => {
    return structureObj.meta?.find(m => m.name === "shortdesc")?.value
}


const SiteSectionTiles = ({section, defaultIcon, contentThumbnail, content, structure, classes, children, externalSites}: any) => {
    const combined = [...structure.children, ...(externalSites || [])]
    return (<div className={classes.siteSectionTiles}>
        <div className={classes.siteSectionTilesContainer}>
            <div className={classes.siteSectionTilesWrapper}>
                <I18nMessage id={section}
                             className={classes.siteSectionTilesTitle}
                             variant="h3">
                    {contentThumbnail && <img alt={"thumbnail"} src={contentThumbnail} className={`${classes.siteSectionTilesIcon}`}/>}
                </I18nMessage>
            </div>
            <VersionSelector versions={content.versions}
                             currentVersion={content.current_version}/>
            <div className={classes.siteSectionBreadcrumb}>
                <PortalBreadcrumb
                    skipFormatting={true}
                    breadcrumbs={content.breadcrumbs}
                    section={section}
                />
            </div>
        </div>
        <div className={classes.siteSectionTilesLinks}>
            {combined?.map((structureObj, index) => {
                const contentObj = (content.children && content.children[index]) || {};
                const shortDesc = contentObj?.shortDescription || contentObj?.shortdesc
                const thumbnail = contentObj?.thumbnail || structureObj?.thumbnail || defaultIcon
                const shortDescription = shortDesc || structureObj?.shortDescription || structureObj?.shortdesc || shortDescriptionFromMeta(structureObj) || null
                return (<div key={contentObj.href + index.toString()} className={classes.siteSectionTilesLinksItem + ` ${structureObj.outputclasses?.join(" ")}`}>
                    <PortalLink
                        className={classes.siteSectionHref}
                        href={structureObj.href}>
                        {thumbnail ? (<img alt={"thumbnail"} src={thumbnail} className={`no-alt ${classes.siteSectionIcon}`}/>) : null}
                        <div className={classes.siteSectionTextWrapper}>
                            <I18nMessage id={structureObj.title} className={classes.siteSectionText}/>
                            {shortDescription &&
                            <I18nMessage id={shortDescription} className={classes.siteSectionDescription}/>}
                        </div>
                    </PortalLink>
                </div>);
            })}
        </div>
        {children}
    </div>);
}

export default withStyles(siteSectionTileStyles, {name: "SiteSectionTiles"})(SiteSectionTiles);